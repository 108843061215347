// Rules for printing

$print-width: 210mm;
$print-height: 297mm;

@media print {
    // Be careful of the priority
    .print-hide {
        opacity: 0;
    }

    .print-rm {
        display: none;
    }
}

// Show only when printing
// Be careful of the priority
.print-show {
    opacity: 0;

    @media print {
        opacity: 1;
    }
}

.print-add {
    display: none;

    @media print {
        display: unset;
    }
}

@page {
    size: A4 portrait;
    margin: 0;
    padding: 0;
}
