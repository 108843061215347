@import 'colors.scss';
@import 'text.scss';
@import 'shadows.scss';
@import 'anim.scss';
@import 'widgets.scss';
@import 'print.scss';

body, html {
  width: 100%;
  height: 100%;
  margin: 0;

  align-items: center;
  justify-content: center;

  font-family: 'Roboto', sans-serif;
  background-color: $white;

  scroll-behavior: smooth;
  overflow-x: hidden;

  @media print {
    overflow: visible;

    width: $print-width;
    height: $print-height;
  }
}

i {
  color: $black;
}

.page {
  width: 100vw;
  height: 100vh;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    width: $print-width;
    height: $print-height;

    box-sizing: border-box;
  }
}
