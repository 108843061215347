@import "../common/style.scss";
@import "../widgets/Dots.scss";
@import "../widgets/ProjectCard.scss";

$footer-height: 64px;

.Projects {
    .bg {
        box-sizing: border-box;

        max-width: 100vw;

        display: flex;
        flex-wrap: wrap;
        align-items: center;

        z-index: -100;

        .project-wrapper {
            width: 50%;
            box-sizing: border-box;

            padding: 3vh 4vw;

            @media (orientation: portrait) {
                width: 100vw;
            }

            @media (max-height: 900px) {
                padding: 2vh 2vw;
            }
        }
    }

    .bg-grey {
        background-color: $grey;

        height: $project-card-height;

        padding-bottom: #{$ndots / 2}vw;

        @media (orientation: portrait) {
            height: unset;
        }

        @media print {
            height: 100vh;
        }

        .project-wrapper {
            height: 100%;

            @media (orientation: portrait) {
                height: 50%;
            }

            @media print {
                width: 50%;
                height: 33.3vh;

                padding: 8px;
            }
        }
    }

    .bg-secondary {
        @extend .bg-grey;

        background-color: $accent;

        @media print {
            background-color: $grey;
        }
    }

    .bg-next {
        padding-top: #{$ndots / 2}vw;
        padding-bottom: #{$ndots / 2}vw;

        height: #{$project-card-height * 1.2};

        @media (orientation: portrait) {
            height: unset;
        }
    }

    // Used to avoid the Dots bottom overflow with footer
    .footer-spacer {
        padding-top: 5vw;

        background-color: $black;
    }

    footer {
        color: $white;
        background-color: $black;

        padding: 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            flex: 1;

            @media (orientation: portrait) {
                flex: 0;
            }
        }

        .github {
            @extend .transition150;

            height: $footer-height;
            transform: scale(1);

            @media (orientation: portrait) {
                height: 36px;
            }
        }

        .github:hover {
            transform: scale(1.15);
        }

        h4 {
            font-weight: 300;
            font-size: 36px;
            margin: 8px 8px;

            justify-self: center;
            text-align: center;

            @media (orientation: portrait) {
                font-size: 24px;
            }
        }

        .subtitle {
            margin: 0;

            font-size: 24px;

            @media (orientation: portrait) {
                font-size: 12px;
            }
        }

        .made-with {
            display: flex;
            align-items: flex-end;

            @media (orientation: portrait) {
                flex-direction: column;
                align-items: center;
            }

            .spacer {
                flex: 1;

                @media (orientation: portrait) {
                    flex: 0;
                }
            }

            p {
                margin: 4px 8px 4px 4px;

                @media (orientation: portrait) {
                    width: 0;
                    height: 0;
                    opacity: 0;
                }
            }

            img {
                margin-right: 8px;
                height: $footer-height;

                @media (orientation: portrait) {
                    height: 36px;
                    margin-right: 0;
                }
            }
        }
    }

    .info-copyright {
        color: $grey;
        cursor: pointer;

        @media (orientation: landscape) {
            margin-left: 4px;
        }
    }
}
