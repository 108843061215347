@import "../common/style.scss";
@import "../widgets/Dots.scss";

$project-card-radius: 48px;
$project-card-radius-print: 24px;
$project-card-height: 90vh;
$project-fab-size: 64px;

.project-card {
    @extend .card16;
    @extend .transition200;

    position: relative;
    top: 0px;

    box-sizing: border-box;
    width: 100%;
    height: 100%;

    padding: $project-card-radius;
    border-radius: $project-card-radius;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    cursor: pointer;

    z-index: 10;

    @media (orientation: portrait) and (max-width: 599px) {
        padding: #{$project-card-radius * 3 / 4};
    }

    @media print {
        padding: $project-card-radius-print;
        border-radius: $project-card-radius-print;
    }

    a {
        color: $primary;
    }

    // Header
    .header {
        display: flex;
        align-items: center;

        padding-bottom: 8px;

        h4 {
            color: $primary;
            font-size: 32px;
            font-weight: 400;

            padding: 0;
            margin: 0;

            @media (orientation: landscape) and (max-width: 1200px),
                    (orientation: portrait) {
                font-size: 24px;
            }

            @media print {
                font-size: 20px;
            }
        }

        .tag {
            color: $accent;

            border: solid 1px $accent;
            border-radius: 24px;

            padding: 4px 16px;
            margin: 0 8px;

            text-align: center;

            @media (orientation: landscape) and (max-width: 1200px),
                    (orientation: portrait) {
                margin: 0 4px;
                padding: 4px 8px;
                font-size: 10px;
            }
        }

        .spacer {
            flex: 1;
        }

        .icon {
            height: 48px;

            padding-left: 8px;

            @media (orientation: landscape) and (max-width: 1200px),
                    (orientation: portrait) {
                height: 36px;
                padding-left: 4px;
            }
        }
    }

    .extra-image {
        width: 64px;
        height: 64px;

        @media print, (orientation: portrait) and (max-width: 599px) {
            width: 48px;
            height: 48px;
        }
    }

    // Image
    .image-wrapper {
        width: 100%;
        height: 75%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media print {
            height: 50%;
        }

        .image {
            @extend .transition150;

            flex: 1;

            max-width: 100%;
            max-height: 100%;
            object-fit: contain;

            border-radius: #{$project-card-radius / 2};
        }

        .image-fab-wrapper {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            top: -50%;

            .image-fab {
                @extend .transition200;
                @extend .shadow8;

                position: absolute;

                background-color: $primary;
                padding: 8px;
                border-radius: 50%;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .hint-wrapper {
                position: absolute;
            }

            .hint-card {
                @extend .transition200;
                @extend .shadow16;

                position: relative;

                background-color: $grey800;
                border-radius: 16px;
                padding: 4px 16px 4px 16px;

                p {
                    color: $white;
                    font-size: 12px;
                    white-space: nowrap;
                }
            }
        }
    }

    .badge-wrapper {
        height: 10%;

        display: flex;
        align-items: flex-end;

        .badge {
            @extend .transition150;

            transform: scale(1);
            transform-origin: center;

            max-width: 100%;
            max-height: 64px;
        }

        .badge .material-symbols-outlined {
            font-size: 2rem;
        }

        p.badge {
            display: inline-block;
            margin: 0;
            padding: .2rem;
            margin-left: .5rem;
        }

        .badge:hover {
            transform: scale(1.2);
        }
    }
}

// Animatables
.image {
    opacity: 1;
}

.image-fab, .project-card .hint-card {
    opacity: 0;
}

.project-card .hint-card {
    top: $project-fab-size;
}

.image-fab-icon {
    @extend .transition200;

    display: inline-block;

    height: #{$project-fab-size * .85};
}

.project-card:hover {
    @extend .shadow32;

    top: -2px;

    .image {
        opacity: .2;
    }

    .image-fab, .hint-card {
        opacity: 1;
    }

    .hint-card {
        top: $project-fab-size * .85;
    }

    .image-fab-icon {
        height: $project-fab-size;
    }
}
