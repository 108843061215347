@import 'anim.scss';
@import 'colors.scss';

.raised-button {
    @extend .transition150;
    @extend .card16;

    cursor: pointer;
    background-color: $white;
}

.raised-button:hover {
    @extend .shadow24;

    background-color: $grey200;
}

.raised-button:active {
    background-color: $grey300;
}

.hint-wrapper {
    position: absolute;

    min-width: 6vw;
}

.hint-card {
    @extend .shadow16;
    @extend .transition200;

    position: relative;

    background-color: $grey800;
    border-radius: 16px;

    min-width: 100%;
    box-sizing: border-box;
    padding: 4px 16px 4px 16px;

    p {
        color: $white;
        font-size: 12px;
        white-space: nowrap;
        text-align: center;
    }
}
