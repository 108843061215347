@import '../common/print.scss';
@import '../common/colors.scss';

@media print {
    .app-layout {
        display: flex;
        flex-direction: column;
    }

    .section {
        display: flex;
        flex-direction: row;

        box-sizing: border-box;
        width: 100vw;
        height: 100vh;
    }

    .print-rhs {
        display: flex;
        flex-direction: column;

        box-sizing: border-box;

        width: 100%;
        max-height: 100vh;

        background-color: $grey200;
    }
}