@import "../common/style.scss";

// Change also npairs within widgets/Dots.js
$ndots: 10;

.dots-wrapper {
    position: relative;

    .dots {
        position: absolute;
        top: #{-$ndots / 2}vw;
        display: flex;

        .dot {
            width: #{100 / $ndots}vw;
            height: #{100 / $ndots}vw;

            border-radius: 50%;
        }

        .dot-grey {
            background-color: $grey;
        }

        .dot-accent {
            background-color: $accent;
        }

        .dot-black {
            background-color: $black;
        }
    }
}
