// Palette

$primary: #2196F3;
$primary-light: #64B5F6;

$accent: #FF6E40;

$grey: #EEEEEE;
$white: #FAFAFA;
$black: #212121;
$grey200: #EEEEEE;
$grey300: #E0E0E0;
$grey400: #BDBDBD;
$grey500: #9E9E9E;
$grey600: #757575;
$grey700: #616161;
$grey800: #424242;
$grey900: #212121;

.primary {
    background-color: $primary;
    color: $white;
}

.text-primary {
    color: $primary;
}

.accent {
    background-color: $accent;
    color: $white;
}

.text-accent {
    color: $accent;
}