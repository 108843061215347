// Animations and transitions

.transition100 {
    transition: ease-in-out 100ms;
}

.transition150 {
    transition: ease-in-out 150ms;
}

.transition200 {
    transition: ease-in-out 200ms;
}

.transition250 {
    transition: ease-in-out 250ms;
}