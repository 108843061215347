// Typography

.text {
    font-family: 'Roboto', sans-serif;
}

h1 {
    @extend .text;

    font-size: 96px;
    letter-spacing: -1.5px;
    font-weight: 100;

    // Mobile phones
    @media (max-width: 520px) {
        font-size: 48px;
    }

    @media print {
        font-size: 36px;

        font-weight: 200;
    }
}

h2 {
    @extend .text;

    font-size: 60px;
    letter-spacing: -0.5px;
    font-weight: 300;

    // Mobile phones
    @media (max-width: 520px) {
        font-size: 24px;
    }

    @media print {
        font-size: 20px;
        font-weight: 400;
    }
}

h3 {
    @extend .text;

    font-size: 48px;
    letter-spacing: 0px;
    font-weight: 500;
}

p {
    @extend .text;
}

a {
    @extend .text;
}

.button-text {
    @extend .text;

    font-size: 14px;
    font-weight: medium;
    letter-spacing: 1.25;
}
